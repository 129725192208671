/* Component Dependencies */
var cardNavigationTemplate = require('templates/cardNavigation.hbs');
var AriesComponent = require('libs/aries-component');
var ResponsiveUtils = require('utils/responsiveUtils');
AriesComponent.create({
	type: 'cardNavigation',
	template: {
		'cardNavigation': cardNavigationTemplate
	},
	events: {
		'click .js-main-menu-link': 'toggleMenu',
		'mouseenter .js-main-menu-link': 'toggleMenu',
		'focusin .js-main-menu-link': 'toggleMenu',
		'mouseleave .l-main-nav-container': 'closeSubMenu',
		'click .js-toggle-nav-control': 'toggleNavControl',
		'click .js-nav-scroll': 'scrollNavLeftRight',
		'click .js-nav-close' : 'closeNavControl'
	},
	options: {
		mainMenu: ".js-main-menu",
		topNav: ".js-top-nav-container",
		opened: ".opened",
		openedCls: "opened",
		subMenuItem: ".l-sub-menu-container li",
		subMenuLink: ".js-sub-nav-links",
		arrowIcon: ".t-arrow-icon",
		mainNav: ".l-main-nav-container",
		overlayOpened: "opened t-darker-alt-overlay",
		overlay: "t-darker-alt-overlay",
		downArrowCls: "t-icon-arrow-down",
		rightArrowCls: "t-icon-arrow-right",
		iconsCls: "t-icon-arrow-right t-icon-arrow-down",
		hiddenClass: "l-display-none",
		boldCls: "t-font-weight-bold",
		currentFirstNavItem:1
	},
	bindEvents: function () {
		var _self = this;
		document.nav = this;
		_self.setOverlayClass.bind(_self);
		_self.currentMainMenuSelector();
		_self.callOnScroll();
		_self.callOnResize();
		_self.getMainMenuBoundries();
		_self.handleTabs();

		if (_self.$el.find(".l-nav-container li:last-child").hasClass("js-current-page")) {
			_self.$el.find(".t-bg-member-alt.l-scroll-link.right").trigger("click");
		}
	},
	//to handle tabbing in submenus
	handleTabs: function () {
		var _self = this;
		_self.isFocused = false;
		_self.navContainer = _self.$el.find(".l-nav-container");
		$('body').on('focusin', function(e) { 
			if(e.target === _self.navContainer || _self.navContainer.has(e.target).length) {
				_self.isFocused = true;
			} else {
				if(_self.isFocused) {
					_self.isFocused = false;
					_self.closeSubMenu(e);
				}
			}
		})
	},
	//responsive util initialization to check view ports
	initResponsiveUtils: function () {
		this.responsiveUtils = ResponsiveUtils.create({});
		this.responsiveUtils.init();
	},
	//set dark or light over lay class
	setOverlayClass: function () {
		var overlayCls = this.$el.data('overlay');
		this.options.overlayOpened = 'opened' + ' ' + overlayCls;

		this.options.overlay = overlayCls;
	},
	//adds overlay gradiant and add class opened on submenus and Main menu items
	toggleCurrentNav: function (currentNav, opened) {
		if (currentNav.hasClass(this.options.openedCls)) {
			currentNav.removeClass(this.options.openedCls).not(this.currentPageMenu).removeClass(this.options.overlay);
		} else {
			opened.removeClass(this.options.openedCls).not(this.currentPageMenu).removeClass(this.options.overlay);
			currentNav.addClass(this.options.overlayOpened);
		}
	},
	//closes submenu on desktop when user leaves the nav bar
	closeSubMenu: function (obj) {
		var _self = this;
		_self.initResponsiveUtils();
		var isDesktop = _self.responsiveUtils.isDesktop();
		if (isDesktop || obj =='resize') {
			_self.$el.find(_self.options.opened)
				.removeClass(_self.options.openedCls)
				.not(_self.currentPageMenu)
				.removeClass(_self.options.overlay);
			_self.$el.find(this.options.arrowIcon).removeClass(this.options.downArrowCls).addClass(this.options.rightArrowCls);
		}
	},
	// function  called depending on hover (Desktop) and click (tablet)
	// and toggles the currently selected main menu class
	toggleMenu: function (event) {
		var _self = this;
		_self.initResponsiveUtils();
		var currentNav = $(event.target).parents(_self.options.mainMenu);
		var currentChildNav = currentNav.find(_self.options.subMenuItem);
		var isDesktop = _self.responsiveUtils.isDesktop();
		if ((event.type == "mouseenter" || event.type == "focusin") && isDesktop) {
			_self.toggleSubMenu(event);
			_self.adjustLeftOfSubmenus(event.target);
			_self.setScrollOnsubMenu();
		} else if (event.type === 'click' && !isDesktop) {
			if ( currentChildNav.length > 0 ) {
				event.preventDefault();
			}
			_self.adjustLeftOfSubmenus(event.target);
			_self.toggleSubMenu(event, true);
			event.stopPropagation();
		}
	},
	// //toggles submenus, arrow icons and header nav and text inside header nav

	toggleSubMenu: function (event, isClick) {
		var _self = this;
		var opened = _self.$el.find(_self.options.opened);
		var currentNav = $(event.target).parents(_self.options.mainMenu);
		var currentChildNav = currentNav.find(_self.options.subMenuItem);
		var currentText = $(event.target).closest('.js-main-menu-link').find('.js-main-menu-text').text();
		currentNav.find(_self.options.arrowIcon).addClass('current');
		var arrowIcon = _self.$el.find(_self.options.arrowIcon).not('.current');
		arrowIcon.removeClass(_self.options.downArrowCls).addClass(_self.options.rightArrowCls);
		_self.$el.find('.current').toggleClass(this.options.iconsCls).removeClass('current');
		_self.$el.find('.js-current-main-menu').removeClass('js-current-main-menu' + _self.options.overlay);
		_self.$el.find('.js-current-page').addClass(_self.options.overlay);
		currentNav.addClass('js-current-main-menu');
		_self.updateTopNav(currentText);
		_self.toggleCurrentNav(currentNav, opened);
		if (isClick && currentChildNav.length < 1) {
			_self.toggleNavControl(event, true);
		}
	},
	//hide nav bar on top and open nav bar below on tablet and mobile
	toggleNavControl: function (event, followThroughLink) {
		if (!followThroughLink) {
			event.preventDefault();
		}
		this.$el.find(this.options.mainNav).toggleClass(this.options.hiddenClass);
		this.$el.find(this.options.topNav).toggleClass(this.options.hiddenClass);
		this.openCurrentSubMenu();
		this.navTakeOver();
	},
	closeNavControl: function(event) {
		this.$el.find(this.options.mainNav).toggleClass(this.options.hiddenClass);
		this.$el.find(this.options.topNav).toggleClass(this.options.hiddenClass);
		this.navIsNotTakeOver();
	},
	navTakeOver: function(_self) {
		var _self = _self || this;
		var isMobile = _self.responsiveUtils.isMobile();
		if (isMobile && _self.$el.find(_self.options.topNav).hasClass(_self.options.hiddenClass) ) {
			_self.navIsTakeOver();
		} else {
			_self.navIsNotTakeOver();
		}
	},
	navIsTakeOver: function(_self) {
		this.$el.addClass('is-nav-takeover');
		$('html').addClass('is-nav-takeover-html');
		if(this.$el.data('isfixed')){
			this.$el.addClass('is-nav-fixed');
		}	
	},
	navIsNotTakeOver: function(_self) {
		this.$el.removeClass('is-nav-takeover');
		$('html').removeClass('is-nav-takeover-html');
		if(this.$el.data('isfixed')){
			this.$el.removeClass('is-nav-fixed');
		}
	},
	openCurrentSubMenu: function () {
		var $currentMainMenu = this.$el.find('.js-current-main-menu');
		$currentMainMenu.addClass(this.options.overlayOpened);
		$currentMainMenu.find(this.options.arrowIcon).removeClass(this.options.rightArrowCls).addClass(this.options.downArrowCls);
		$currentMainMenu.removeClass('js-current-main-menu');
	},
	//update top nav text
	updateTopNav: function ($text) {
		this.$el.find('.js-toggle-nav-control .label').text('').text($text);
	},
	// on page load selects the mainMenu that corresponds to the page Url
	// adds overlay class and updates top nav text
	currentMainMenuSelector: function () {
		var pageURL = window.location.href;
		var _self = this;
		var items = _self.$el.find('.js-sub-menu a, .js-main-menu-link a');
		_self.initResponsiveUtils();
		var isDesktop = _self.responsiveUtils.isDesktop();
		for (var i = 0; i < items.length; i++) {
			var item = items[i];
			var $item = $(item);
			var itemsURL = $item.attr("href");
			if (itemsURL && _.includes(pageURL, itemsURL)) {			
				if (!$item.hasClass('l-main-menu-link')) {
					// makes selected submenu item bold
					$item.addClass(_self.options.boldCls);
				}
				//add overlay to selected item's main menu and add text to top nav
				var parent = $item.parents(_self.options.mainMenu).addClass('js-current-main-menu js-current-page').addClass(_self.options.overlay);
				parent.find(_self.options.dropDownIcon).toggleClass(_self.options.iconsCls);
				_self.updateTopNav(parent.find('.js-main-menu-text').text());
				if (isDesktop) parent.addClass(_self.options.overlay);
				_self.currentPageMenu = _self.$el.find('.js-current-page');
				return false;
			}
		}
	},
	// adding stick behavior on page scroll  
	callOnScroll: function () {
		if (this.$el.data('sticktotop')) {
			var _self = this;
			_self.sticky = _self.$el.offset().top;
			$(window).on('scroll', function( event){
				 _self.stickCardNavToTop(_self, event);
				 _self.getMainMenuBoundries(_self, event);
				 _self.setScrollOnsubMenu();
			});
		}
	},
	callOnResize:function(){
		var _self = this;
		$(window).on('resize orientationchange', _self, function(event){
			var _self = event.data;
			_self.closeSubMenu('resize');
			_self.getMainMenuBoundries(_self, event);
			_self.navTakeOver(_self);
		});
	},
	stickCardNavToTop: function ($this) {
		var _self = $this;
		var sticky = _self.sticky;
		var navbar = _self.$el;
		
		if (window.pageYOffset >= sticky) {
			navbar.addClass("t-sticky-nav");
			this.isSticky = true;
			if(navbar.find('.opened').length) {
				_self.adjustLeftOfSubmenus('.opened .l-nav-header');
			}
		} else {
			navbar.removeClass("t-sticky-nav");
			this.isSticky = false;
		}
	},
	//scrolls left and right with the offset 
	scrollNavLeftRight: function (e) {
		e.preventDefault();
		var _self = this;
		var left = $(e.target).hasClass('t-icon-arrow-left');
		var navCont = _self.$el.find('.l-nav-container');
		var navItem = navCont.find('.js-main-menu');
		var firstOffSet = this.leftOfFirstMenu;
		var currentFirstNavItem = _self.options.currentFirstNavItem;
		var newFirstNavItem = 0;
		var maxItem = navItem.length;
		if (left) {
			newFirstNavItem = 0;
			_self.options.currentFirstNavItem = 1;
		} else if(currentFirstNavItem < maxItem){
			newFirstNavItem = navCont.find('.js-main-menu:nth-child(' + (currentFirstNavItem + 1) + ')')[0].offsetLeft;
			_self.options.currentFirstNavItem = currentFirstNavItem + 1;
		}
		navCont.scrollLeft(newFirstNavItem);

		setTimeout(function(){ _self.getMainMenuBoundries(_self, e); }, 500);		
	},
	// disables partially and not visiable main nav from click and hover
	disablePartiallyVisibleMenu:function(boundries, obj){
		var objBoundries = obj.getBoundingClientRect();
	 	if(Math.floor(objBoundries.left) < boundries.parentLeft || Math.floor(objBoundries.right) > boundries.parentRight  ){
			$(obj).find('a').addClass('t-cursor-disabled');
		}else{
			$(obj).find('a').removeClass('t-cursor-disabled');
		}
	},
	// as main menu are scolled left and right, following functions add css left such that 
	// the sub menus align right below main menu.
	adjustLeftOfSubmenus:function(obj){
		var offBy;
		var $padding = 8;
		var $obj = $(obj);
		if (!$obj.hasClass('js-main-menu')) {
			$obj = $obj.closest('.js-main-menu');
		}
		if (this.$el.hasClass('t-sticky-nav')) {
			offBy = $obj.offset().left + $padding;
		} else {
			offBy = $obj.position().left + $padding;
		}
		$obj.find('.js-sub-menu').css('left', offBy);
	},
	// checks desktop or mobile view port calc main menu boundry , stores left of first main menu and right of last main menu
	// left of first main menu will be used to determine if element is fully visiable or not
	// and nav left will determine if the most left element is visiable or not when nav is scroll to top. 
	getMainMenuBoundries:function($this, event){
		var _self = $this;
		if(!event) _self = this;
		_self.initResponsiveUtils();
		var isMobile = _self.responsiveUtils.isMobile();
		var $container = _self.$el.find('.l-nav-container');
		var $firstChild  = _self.$el.find('.js-main-menu:first-child');
		var $lastChild = _self.$el.find('.js-main-menu:last-child');
		var boundries = _self.determineBoundries($container, $firstChild, $lastChild);
		var overflowDriection = _self.determineOverflow(boundries);
		var navItem = _self.$el.find('.js-main-menu');
		if(!isMobile){
			navItem.each(function (i, obj) {
				_self.disablePartiallyVisibleMenu(boundries , obj);
			});
			this.$el.find('.l-main-nav-container').attr('data-overflowing', overflowDriection);
		}
	},
	determineBoundries:function($container, $firstChild, $lastChild){
		var containerMetrics = $container[0].getBoundingClientRect();
		var firstChildMetrics =  $firstChild[0].getBoundingClientRect();
		var lastChildMetrics  =   $lastChild[0].getBoundingClientRect();
		return{
			parentLeft:Math.floor(containerMetrics.left),
			parentRight:Math.floor(containerMetrics.right),  
			childLeft: Math.floor(firstChildMetrics.left),
			childRight: Math.floor(lastChildMetrics.right)
		};
	},
	determineOverflow:function(boundries) {
		if (boundries.parentLeft > boundries.childLeft && boundries.parentRight < boundries.childRight) {
			return 'both';
		} else if (boundries.childLeft < boundries.parentLeft) {
			return 'left';
		} else if (boundries.childRight > boundries.parentRight) {
			return 'right';
		} else {
			return 'none';
		}
	},
	// if submenu item list height is greater than windows height, make submenu scrollable
	setScrollOnsubMenu:function() {
		var _self = this,
		submenu = _self.$el.find('.js-current-main-menu .l-sub-menu-container');
		if(_self.$el.hasClass('t-sticky-nav')){
			submenu.addClass('l-sub-menu-scroll');
		} else{
			submenu.removeClass('l-sub-menu-scroll');
		}
	}
});